@font-face {
  font-family: 'handel';
  src: local('handel'), url(../public/handel.ttf) format("truetype")
}

body,h1{
  margin:0; 
  padding: 0;
}

body{
 background: radial-gradient(#e96c1c,#e3772e);
}

h1{
  position: relative;
  top: 0;
  color:white;
  text-transform: uppercase;
  font-size: 1.5em;
  text-align: center;
}

h3 {
  color:white;
  font-size: 1.5em;
}

p {
  color:white;
}

nav{
  display: flex;
  align-items: stretch;
  position: sticky;
  top: 0;
  background-color: #e96c1c;
}

footer,nav,article{
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important
}

nav div{
  display: flex;
  align-items: center;  
}

main{
  min-height: 80px;
  z-index: -1;
}

a.active {
  background: #111;
}

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  min-height: calc(100vh - 2rem);
}
