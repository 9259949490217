.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: row;
    padding: 0;
}

.logo{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    z-index: -1;
}

.img{
    position: absolute;
    width: 13vw;
    margin: auto;
    top: -13vw;
    left: 0;
    right: 0;
    bottom: 0;
}

.logo_text{
    font-family: 'handel', sans-serif;
    font-weight: bolder;
    font-size: 10vw;
    letter-spacing: 1vw;
    text-transform: uppercase;
    color:white;
}

.logo_text span{
    text-shadow: -0.02em -0.02em 0.1em black;
}

.logo_text:before {
   content: attr(data-text);
   position:absolute;
   top:0;
   left:0;
   transform-origin: bottom;
   transform: rotateX(180deg);
   line-height:0.9em;
   background:linear-gradient(0deg,#fff 0, transparent 80% );
   -webkit-background-clip: text;
   color:transparent;
   opacity: 0.5;
}

.article{
    text-decoration: inherit;
    width:100%;
    max-width:100%;
    display: flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
}

.article:hover{
    background-color: rgba(0, 0, 0, 0.158);
    font-size: 1.2em;
}

@media (min-width: 1200px){
    .article{
        flex: 0 0 auto;
        width: 50%;
    }
    .container h1{
        top: -25vh;
    }
}