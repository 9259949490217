.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    min-height: calc(100vh - 2rem);
}

.container article{
    margin:2rem 0rem;
    padding: 1rem 2rem;
}

.img{
    width: 7rem;
    vertical-align: middle;
}

.main p{
 font-size: 1.3rem;
}
.nav ul {
    display: flex;
    justify-content:flex-start;
    align-items: stretch ;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    top: 0;
    width: 100%; 
}
  
.nav li {
    flex: none;
    display: flex;
    align-items: center; 
    float: left; 
}


  
.nav li a {
    display:flexbox;
    color: white;
    font-size: 1rem;
    font-weight: bolder;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
}



.nav li:hover {
    background: #111;
}
