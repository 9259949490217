.contact{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: center;
    padding: 1rem 2rem;
}

.contact div{
    padding: 0.5rem;
}

.contact a{
    color:white;
    text-decoration: none;
}

.contact p{
    font-size: 1.3em;
}